import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useRef } from 'react';

import { PostEntity } from '../../types';
import { Categories } from '../Categories';
import { PostMedia } from './Media';

const VerticalPost = ({ id, type, source, categories, summary, title }: PostEntity) => {
    const router = useRouter();
    const categoriesRef = useRef(null);
    return (
        <li
            className={
                'group vertical-post bg-white pb-6 flex flex-col h-full inline-hover transform translate-y-0 hover:-translate-y-1.5 radius-md rounded-lg shadow-md hover:shadow-2xl'
            }>
            <div
                tabIndex={0}
                role={'button'}
                className={'flex flex-col text-left h-full'}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                        router.push(`/post/${id}`);
                    }
                }}>
                <PostMedia source={source} type={type} id={id} title={title} />
                <div className={'h-full flex flex-col bg-white z-10 relative'}>
                    <div ref={categoriesRef}>
                        <Categories className={'pl-8 border-l-2 pr-4'} categories={categories} />
                    </div>
                    <h3
                        className={`text-xl font-semibold m-0 text-blue-dark hover:text-blue-light active:text-blue-light focus:text-blue-light inline-hover`}>
                        <Link href={`/post/${id}`}>
                            <a className={'block px-8 leading-7'}>{title}</a>
                        </Link>
                    </h3>
                    <div
                        className={'text-base text-gray-main mt-4 hidden lg:block px-8 leading-6'}
                        dangerouslySetInnerHTML={{ __html: summary }}
                    />
                    <Link href={`/post/${id}`} passHref>
                        <a
                            className={
                                'group-hover:opacity-100 read-more lg:opacity-0 mt-3 px-8 font-medium flex items-end justify-end flex-1 opacity-100 text-sm text-blue-dark hover:text-blue-light active:text-blue-light focus:text-blue-light inline-hover'
                            }>
                            Leia Mais
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                className={
                                    'h-3.5 w-3.5 ml-1 mb-0.5 inline-hover transform group-hover:translate-x-1.5'
                                }
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                />
                            </svg>
                        </a>
                    </Link>
                </div>
            </div>
        </li>
    );
};

export default VerticalPost;
