import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';

import { PostEntity } from '../../types';
import { Iframe } from '../Iframe/lazy';

export const PostMedia: React.FC<Pick<PostEntity, 'title' | 'source' | 'id' | 'type'>> = ({
    type,
    source,
    id,
    title
}) => {
    if (type === 'video') {
        return (
            <Iframe
                src={source}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                fullRounded={false}
            />
        );
    }

    return (
        <Link href={`/post/${id}`}>
            <a>
                <Image
                    width={800}
                    height={450}
                    placeholder={'blur'}
                    layout={'responsive'}
                    title={title}
                    alt={title}
                    src={`/images/maxresdefault/${source}.jpg`}
                />
            </a>
        </Link>
    );
};
